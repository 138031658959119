import img1 from 'assets/images/PrintivityCloud/image1.png';
import img2 from 'assets/images/PrintivityCloud/image2.png';
import img3 from 'assets/images/PrintivityCloud/image3.png';
import img4 from 'assets/images/PrintivityCloud/image4.png';
import img5 from 'assets/images/PrintivityCloud/image5.png';
import img6 from 'assets/images/PrintivityCloud/image6.png';
import img7 from 'assets/images/PrintivityCloud/image7.png';
import img8 from 'assets/images/PrintivityCloud/image8.png';

export const carouselImages = [
  {
    url: img1,
    alt: 'Image 1',
    title: 'Image1',
  },
  {
    url: img2,
    alt: 'Image 2',
    title: 'Image2',
  },
  {
    url: img3,
    alt: 'Image 3',
    title: 'Image3',
  },
  {
    url: img4,
    alt: 'Image4',
    title: 'Image4',
  },
  {
    url: img5,
    alt: 'Image 5',
    title: 'Image5',
  },
  {
    url: img6,
    alt: 'Image 6',
    title: 'Image 6',
  },
  {
    url: img7,
    alt: 'Image 7',
    title: 'Image 7',
  },
  {
    url: img8,
    alt: 'Image 8',
    title: 'Image 8',
  },
];
