import * as React from 'react';
import Button from 'styleguide/components/Button/Button';
import logo from 'assets/images/printivity-logo.png';
import { homePagePath } from 'bundles/App/routes';

const WhoWeAre = () => (
  <section className="flex justify-center" id="whoWeAre">
    <div className="bg-neutral-100 rounded-xl flex items-center justify-center flex-col w-full max-w-full 2xl:max-w-base mb-10 text-center py-28">
      <div className="flex flex-col items-center px-4">
        <img className="block h-14 w-auto mb-8" src={logo} alt="Printivity" />
        <h3 className="md:text-5xl text-4xl font-hvMedium mb-3">Who We Are.</h3>
        <p className="mb-3">
          Great products, great service, great prices.
          <br />
          Easy to order from. And the fastest turnaround options. <br />
          Also, a genuine appreciation and care for our customers.
        </p>
        <p className="mb-8">
          Why? Because you depend on us to grow your business and tell your stories. <br /> Thanks for helping
          make us the highest rated printing company in the country.
        </p>
        <Button.AsNativeLink
          size="md"
          buttonType="link"
          color="orange"
          outline
          className="hover:shadow hover:!bg-opacity-10 transition duration-150 !text-sm"
          target={homePagePath}
        >
          Go To Printivity
        </Button.AsNativeLink>
      </div>
    </div>
  </section>
);

export default WhoWeAre;
