import * as React from 'react';
import { SvgProps } from 'styleguide/components/Svg/SvgIcon';

interface Props {
  Icon?: (props: SvgProps) => JSX.Element;
  title: string;
  content: string;
}

const Benefit = ({ Icon, title, content }: Props) => (
  <div className="flex flex-col justify-start">
    {!!Icon && <Icon className="!min-h-[2rem] !min-w-[2rem]" />}
    <div className="text-xl font-hvMedium">{title}</div>
    <div className="text-sm text-gray-500">{content}</div>
  </div>
);

export default Benefit;
