import * as React from 'react';

const Title = () => (
  <div className="text-center flex flex-col justify-center mb-8">
    <div className="w-full md:w-[680px] mx-auto">
      <div className="text-neutral-700 font-medium font-hvMedium text-sm mb-6">BENEFITS</div>
      <h3 className="md:text-5xl text-4xl text-blue font-medium font-hvMedium">
        Do <span className="text-default">More With</span> Enterprise Printing.
      </h3>
      <p>We ensure a quick start, seamless project management, and complete transparency.</p>
    </div>
  </div>
);

export default Title;
