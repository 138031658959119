import * as React from 'react';
import Button from 'styleguide/components/Button/Button';
import { servicePath } from 'bundles/App/routes';

const MigrateToPrintivityCloud = () => (
  <section className="flex justify-center">
    <div className="bg-neutral-100 rounded-xl flex items-center justify-center flex-col w-full max-w-full 2xl:max-w-base mb-10 text-center py-32">
      <div className="max-w-[680px] px-4">
        <div className="text-neutral-700 font-medium font-hvMedium text-sm mb-6">GET STARTED</div>
        <h2 className="md:text-6xl text-4xl font-hvMedium">
          Migrate Your Solution To <span className="whitespace-nowrap">Printivity Cloud.</span>
        </h2>
        <p className="text-base">
          Managing print has moved to the cloud and modern day expectations demand a solution that is fast,
          flexible, consistent, and simple.
        </p>
        <Button.AsNativeLink
          dataCy="migrate"
          size="md"
          buttonType="link"
          color="orange"
          outline
          className="hover:shadow hover:!bg-opacity-10 transition duration-150 !text-sm"
          target={servicePath.cloudEnterpriseServicesContactSales}
        >
          Get Started
        </Button.AsNativeLink>
      </div>
    </div>
  </section>
);

export default MigrateToPrintivityCloud;
