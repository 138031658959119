import * as React from 'react';
import SolutionCard from './SolutionCard';
import SolutionCardItem from './SolutionCardItem';
import {
  IconScalability,
  IconUnScalable,
  IconPlatform,
  IconStability,
  IconClockRotate,
  IconNoCoin,
  IconSlow,
  IconInConsistentQuality,
} from 'styleguide/icons';

const CompareSolutions = () => (
  <div className="flex flex-col md:flex-row mb-16">
    <SolutionCard title="Yesterday’s Solution" color="orange" bodyHeight="h-[436px]">
      <SolutionCardItem Icon={IconSlow} content="Slow, Inefficient Document Distribution" />
      <SolutionCardItem Icon={IconInConsistentQuality} content="Inconsistent Branding, Variable Quality" />
      <SolutionCardItem
        Icon={IconNoCoin}
        content="Vendor Sprawl, Decentralized and No Control, No Visibility Over Spend"
      />
      <SolutionCardItem
        Icon={IconUnScalable}
        content="Hardware Driven, Expensive Management, Poor Scalability"
      />
    </SolutionCard>
    <SolutionCard
      title="Printivity Solution"
      color="blue"
      withShadow
      className="z-1 ml-0 md:-ml-4 mt:12 md:mt-0"
      bodyHeight="h-[485px]"
    >
      <SolutionCardItem
        Icon={IconClockRotate}
        content="Standard 24 Hour Turnarounds & Overnight Delivery"
        textStyle="emphasis"
      />
      <SolutionCardItem
        Icon={IconStability}
        content="Brand Consistency, 99% Error-free Rate"
        textStyle="emphasis"
      />
      <SolutionCardItem
        Icon={IconPlatform}
        content="Unified Platform, Admin Controls, Spend Analytics"
        textStyle="emphasis"
      />
      <SolutionCardItem
        Icon={IconScalability}
        content="Fully Scalable Solution, No Hardware or IT Resources Required"
        textStyle="emphasis"
      />
    </SolutionCard>
  </div>
);

export default CompareSolutions;
