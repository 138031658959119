import * as React from 'react';
import Button from 'styleguide/components/Button/Button';
import { servicePath } from 'bundles/App/routes';
import cn from 'classnames';
import css from './Hero.scss';

const Hero = () => (
  <section
    className="flex flex-col justify-center items-center bg-neutral-100 lg:pt-40 lg:pb-36 pt-32 pb-32 relative overflow-hidden"
    id="hero"
  >
    <div
      className={cn(
        'absolute w-[523px] h-[523px] bg-yellow-700 rounded-full filter blur-[200px] right-[271px] -xl:right-0 -top-[34px] -lg:hidden',
        css.firefoxOnly,
        css.safariOnly,
      )}
    />
    <div
      className={cn(
        'absolute w-[523px] h-[523px] bg-orange-700 rounded-full filter blur-[200px] right-[5px] top-[42px] -xl:hidden',
        css.firefoxOnly,
        css.safariOnly,
      )}
    />
    <div
      className={cn(
        'absolute w-[523px] h-[523px] bg-blue-500 rounded-full filter blur-[200px] right-[200px] -xl:right-0 -bottom-[117px] -lg:hidden',
        css.firefoxOnly,
        css.safariOnly,
      )}
    />
    <div className="z-10 px-4 flex items-center flex-col">
      <header className="max-w-[680px] text-center">
        <div className="text-neutral-700 font-medium font-hvMedium text-sm mb-6">THE CLOUD IS HERE</div>
        <h1 className="md:text-6xl text-4xl font-hvMedium">Migrate Your Solution To Printivity Cloud.</h1>
      </header>
      <div className="lg:max-w-[900px] text-center text-base">
        <p className="mt-4 mb-6">
          Managing print has moved to the cloud and modern day expectations demand faster delivery times.
        </p>
        <p>
          Transform your business operations with Printivity Cloud, a gateway for enterprises looking for a
          modern end-to-end workflow and logistics solution.
        </p>
      </div>
      <div className="flex justify-center">
        <Button
          dataCy="heroLearnMoreButton"
          size="md"
          type="button"
          color="blue"
          className="mr-6 !text-sm"
          onClick={() => {
            const elem = document.getElementById('introducing');
            if (elem) {
              elem.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          Learn More
        </Button>
        <Button.AsNativeLink
          dataCy="heroGetStartedButton"
          size="md"
          buttonType="link"
          color="orange"
          outline
          className="hover:shadow hover:!bg-opacity-10 transition duration-150 !text-sm"
          target={servicePath.cloudEnterpriseServicesContactSales}
        >
          Get Started
        </Button.AsNativeLink>
      </div>
    </div>
  </section>
);

export default Hero;
