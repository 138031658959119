import imgFast from 'assets/images/PrintivityCloud/fast.jpg';
import imgFlexible from 'assets/images/PrintivityCloud/flexible.jpg';
import imgConsistent from 'assets/images/PrintivityCloud/consistent.jpg';
import imgSimple from 'assets/images/PrintivityCloud/simple.jpg';

export const storiesData = [
  {
    title: 'Fast.',
    subtitle: 'Industry-Leading 24 Hour Turnaround And Overnight Shipping Options.',
    imageUrlMobile: imgFast,
    imageUrlDesktop: imgFast,
    direction: 'left',
    imageTitle: 'Fast Shipping',
    imageAlt: 'Fast Shipping',
    descriptionFirstParagraph:
      'Order marketing, sales, training, finance, and any other collateral on demand, shipped anywhere in the United States.',
    descriptionSecondParagraph:
      'Lean on our industry-leading 24 hour turnarounds and overnight shipping options for the most time-critical deadlines.',
  },
  {
    title: 'Flexible.',
    subtitle: 'Eliminate equipment costs and leases. Gain scalability.',
    imageUrlMobile: imgFlexible,
    imageUrlDesktop: imgFlexible,
    direction: 'right',
    imageTitle: 'Eliminate equipment costs',
    imageAlt: 'Eliminate equipment costs',
    descriptionFirstParagraph:
      'Cut out fixed costs for hardware, management, and downtime. Consolidate your organization’s volume for pricing incentives.',
    descriptionSecondParagraph:
      'Leverage pay-as-you-go plans to provide flexibility and peace-of-mind for seasonal or peak needs.',
  },
  {
    title: 'Consistent.',
    subtitle: 'Maintain Brand Image Across All Channels.',
    imageUrlMobile: imgConsistent,
    imageUrlDesktop: imgConsistent,
    direction: 'left',
    imageTitle: 'Maintain Brand Image',
    imageAlt: 'Maintain Brand Image',
    descriptionFirstParagraph:
      'Gone are the days of multiple vendors producing multiple versions of your marketing collateral.',

    descriptionSecondParagraph:
      'Printivity Cloud is a multi-site, premier, quality-controlled environment with redundancies, designed to support the needs of brand-centric businesses.',
  },
  {
    title: 'Simple.',
    subtitle: 'Managing and Distributing Documents has Never Been Easier.',
    imageUrlMobile: imgSimple,
    imageUrlDesktop: imgSimple,
    direction: 'right',
    imageTitle: 'Simple',
    imageAlt: 'Simple',
    descriptionFirstParagraph:
      'Get the tools needed to manage and distribute your documents and collateral. Manage user permissions with administrative controls, and track spend through spend analytics.',
    descriptionSecondParagraph:
      'Plus, with our cloud-based system, you can be up and running in days, without heavy lifting from IT.',
  },
];
