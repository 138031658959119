import * as React from 'react';
import cn from 'classnames';

interface Props {
  color: 'orange' | 'blue';
  title: string;
  withShadow?: boolean;
  className?: string;
  bodyHeight: string;
  children: React.ReactNode;
}

const SolutionCard = ({ color, title, withShadow, className, bodyHeight, children }: Props) => (
  <div className={cn('flex flex-col items-center justify-center', className)}>
    <div
      className={cn(
        color === 'orange' ? 'bg-orange-50' : 'bg-blue text-white',
        'rounded-3xl w-72 text-center p-1 -my-5 z-10 relative font-hvMedium',
      )}
    >
      {title}
    </div>
    <div
      className={cn(
        color === 'orange' ? 'bg-[#FEF2F1]' : 'bg-[#E1F4FC]',
        'rounded-3xl w-96 p-6',
        withShadow && 'shadow-xl',
        bodyHeight,
      )}
    >
      {children}
    </div>
  </div>
);

export default SolutionCard;
