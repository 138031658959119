import * as React from 'react';
import Title from './Title';
import Button from 'styleguide/components/Button/Button';
import CompareSolutions from './CompareSolutions';
import { servicePath } from 'bundles/App/routes';

const PrintivityCloud = () => (
  <section
    className="flex flex-col justify-center items-center px-4 mt-16 mb-12 bg-neutral-50 py-32 max-w-base mx-auto rounded-xl"
    id="introducing"
    data-cy="introducingSection"
  >
    <Title />
    <CompareSolutions />
    <Button.AsNativeLink
      dataCy="introducingGetStartedButton"
      size="md"
      buttonType="link"
      color="orange"
      outline
      className="hover:shadow hover:!bg-opacity-10 transition duration-150 !text-sm"
      target={servicePath.cloudEnterpriseServicesContactSales}
    >
      Get Started
    </Button.AsNativeLink>
  </section>
);

export default PrintivityCloud;
