import * as React from 'react';
import { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import cn from 'classnames';

interface Props {
  Icon?: (props: SvgProps) => JSX.Element;
  content: string;
  textStyle?: 'emphasis' | 'body';
}

const SolutionCardItem = ({ Icon, content, textStyle }: Props) => (
  <div className="flex my-10">
    {!!Icon && <Icon className="!min-h-[2rem] !min-w-[2rem]" />}
    <p className={cn(textStyle === 'emphasis' ? 'text-xl font-hvMedium' : 'text-base text-gray-500', 'm-0')}>
      {content}
    </p>
  </div>
);

export default SolutionCardItem;
