import * as React from 'react';

const Title = () => (
  <div className="text-center flex flex-col justify-center mb-8 px-4">
    <div className="w-full md:w-[680px] mx-auto">
      <div className="text-neutral-700 font-medium font-hvMedium text-sm mb-6">INTRODUCING</div>
      <h3 className="md:text-5xl text-4xl text-blue font-medium font-hvMedium whitespace-nowrap">
        <span className="text-default">Printivity</span> Cloud
      </h3>
      <p>
        Today&rsquo;s businesses move fast, and managing your printing needs is all about staying in sync —
        quick turnarounds, reliable brand identity, coherent management tools, and the ability to scale
        responsively without fixed hardware costs.
      </p>
    </div>
  </div>
);

export default Title;
