import * as React from 'react';
import Meta from 'styleguide/components/Meta/Meta';
import Nav from './Nav';
import Hero from './Hero';
import Stories from './Stories/Stories';
import MigrateToPrintivityCloud from './MigrateToPrintivityCloud';
import Copyright from './Copyright';
import WhoWeAre from './WhoWeAre';
import Benefits from './Benefits';
import PrintivityCloud from './PrintivityCloud';
import Carousel from './CloudServicesCarousel';

const CloudEnterpriseServices = () => (
  <>
    <Meta
      title="Cloud Enterprise Services | Printivity"
      canonical="https://www.printivity.com/cloud-enterprise-services"
    />
    <Nav />
    <Hero />
    <Carousel />
    <PrintivityCloud />
    <Benefits />
    <WhoWeAre />
    <Stories />
    <MigrateToPrintivityCloud />
    <Copyright />
  </>
);

export default CloudEnterpriseServices;
