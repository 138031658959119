import * as React from 'react';
import Image from 'styleguide/components/Image/Image';
import { carouselImages } from './carouselImageData';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const CloudServicesCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    draggable: false,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1740,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="relative mx-auto -mt-6 overflow-hidden">
      <Slider {...settings}>
        {carouselImages.map(image => (
          <Image
            className="object-cover shadow-md rounded-3xl !w-[400px] !h-[520px]"
            key={image.url}
            url={image.url}
            alt={image.alt}
            height={520}
            width={400}
            dataCy={image.title}
          />
        ))}
      </Slider>
    </div>
  );
};

export default CloudServicesCarousel;
