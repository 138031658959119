import * as React from 'react';
import Benefit from './Benefit';
import Title from './Title';
import { benefitsData } from './BenefitsData';

const Benefits = () => (
  <section className="flex justify-center mt-16 mb-12 py-16 px-4" id="benefits">
    <div className="max-w-base">
      <Title />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-20 gap-y-8">
        {benefitsData.map(benefit => (
          <Benefit Icon={benefit.Icon} {...benefit} key={benefit.title} />
        ))}
      </div>
    </div>
  </section>
);

export default Benefits;
