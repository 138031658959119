import * as React from 'react';
import Story from 'styleguide/components/Story/Story';
import { storiesData } from './StoriesData';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const DescriptionBlock = ({ firstParagraph, secondParagraph }) => (
  <div>
    <p>{firstParagraph}</p>
    <p>{secondParagraph}</p>
  </div>
);

const Stories = () => (
  <div className="flex justify-center px-4" data-cy="stories">
    <div className="max-w-screen-2xl">
      {storiesData.map(story => (
        <LazyLoad once offset={600} placeholder={LoaderWrapper('h-[707px] flex items-center justify-center')}>
          <Story
            title={story.title}
            titleStyle="lg:!text-6xl !text-5xl"
            subtitle={story.subtitle}
            description={
              <DescriptionBlock
                firstParagraph={story.descriptionFirstParagraph}
                secondParagraph={story.descriptionSecondParagraph}
              />
            }
            descriptionStyle="!font-hvRoman !font-normal"
            imageUrlMobile={story.imageUrlMobile}
            imageUrlDesktop={story.imageUrlDesktop}
            direction={story.direction as 'left' | 'right'}
            imageTitle={story.imageTitle}
            imageAlt={story.imageAlt}
            key={story.title}
          />
        </LazyLoad>
      ))}
    </div>
  </div>
);

export default Stories;
