import {
  IconProgramming,
  IconUnification,
  IconNoCost,
  IconAnalytics,
  IconCloudSpeed,
  IconConvenience,
  IconScalability,
  IconSecurity,
  IconStability,
} from 'styleguide/icons';

export const benefitsData = [
  {
    Icon: IconProgramming,
    title: 'No IT Resources',
    content:
      'Deploy and integrate with existing systems and workflows without engaging internal IT resources.',
  },
  {
    Icon: IconUnification,
    title: 'Unification',
    content: 'Maximize pricing incentives by consolidating previously fragmented production volumes.',
  },
  {
    Icon: IconNoCost,
    title: 'No Hardware Costs',
    content: 'Eliminate equipment leases and managed services costs with pay as you go plans.',
  },
  {
    Icon: IconCloudSpeed,
    title: 'Speed',
    content: 'Rely on 24 hour turnarounds and overnight shipping options for your most critical deadlines.',
  },
  {
    Icon: IconScalability,
    title: 'Scalability',
    content: 'Meet last minute or seasonal demands with production capabilities that scale with you.',
  },
  {
    Icon: IconAnalytics,
    title: 'Analytics',
    content:
      'Track spend, generate reports, and forecast your budget all within a single, coherent platform.',
  },
  {
    Icon: IconStability,
    title: 'Stability',
    content:
      'Leverage our extensive and redundant production infrastructure to eliminate downtime and delays.',
  },
  {
    Icon: IconConvenience,
    title: 'Convenience',
    content: 'Order on-demand, whenever and wherever, 24 hours a day, 7 days a week, 365 days a year.',
  },
  {
    Icon: IconSecurity,
    title: 'Security',
    content: 'Upload and distribute with complete confidence with industry-leading security encryption.',
  },
];
